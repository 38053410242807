var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PanelTableMolecule', {
    attrs: {
      "isLoading": _vm.$apollo.loading,
      "count": _vm.products.length,
      "errors": _vm.errors,
      "title": _vm._f("capitalize")(_vm.$tc('product', 2)),
      "columns": _vm.columns,
      "rows": _vm.rows,
      "pageIndex": _vm.pageIndex,
      "pageSize": _vm.pageSize,
      "createRouteName": 'CreateProduct',
      "selectedRow": _vm.selectedRow,
      "hasClearSearch": _vm.search !== undefined
    },
    on: {
      "pageNumberChange": function pageNumberChange($event) {
        return _vm.pageNumberChange($event);
      },
      "reload": _vm.reload,
      "clear-search": _vm.clearSearch
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }