<template>
  <PanelTableMolecule
    :isLoading="$apollo.loading"
    :count="products.length"
    :errors="errors"
    :title="$tc('product', 2) | capitalize"
    :columns="columns"
    :rows="rows"
    :pageIndex="pageIndex"
    :pageSize="pageSize"
    :createRouteName="'CreateProduct'"
    :selectedRow="selectedRow"
    :hasClearSearch="search !== undefined"
    @pageNumberChange="pageNumberChange($event)"
    @reload="reload"
    @clear-search="clearSearch"
  />
</template>

<script>
import { Edit2Icon, SearchIcon, TrashIcon } from 'vue-feather-icons'
import { debounce } from 'vue-debounce'

import { getRolesFromToken } from '@common/utils'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import ResponsiveImageAtom from '@/components/Atomic/Atoms/ResponsiveImageAtom'

import LIST_PRODUCTS_QUERY from '#/graphql/marketplace/productsQuery.gql'
import DELETE_ONE_I_PRODUCT_MUTATION from '#/graphql/marketplace/deleteOneIProductMutation.gql'

// import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

export default {
  components: {
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      products: [],
      errors: [],
      defaultPage: 1,
      defaultPageSize: 10,
      // searchQuery: '',
      selectedRow: null,
    }
  },
  created() {
    this.triggerSearch = debounce(searchQuery => {
      const search = searchQuery.length > 0 ? searchQuery : undefined
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route?.query,
          page: 1,
          search,
        },
      })
    }, 500)
  },
  computed: {
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasDeletePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    pageIndex() {
      const i = parseInt(this.$route?.query.page)
      return !isNaN(i) ? i : this.defaultPage
    },
    pageSize() {
      return this.$route?.query.perPage ?? this.defaultPageSize
    },
    skip() {
      return (this.pageIndex - 1) * this.pageSize
    },
    search() {
      return this.$route?.query?.search?.length > 0 ? this.$route.query.search : undefined
    },
    // where() {
    //   const where = {
    //     max: this.pageSize,
    //     first: this.skip,
    //     search: this.search,
    //   }
    //   return where
    // },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'name',
          title: 'Name',
          align: 'left',
          width: '10%',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.search ?? ''}
                  placeholder="Search Product"
                  on-valueChange={e => this.triggerSearch(e)}
                  on-cancel={() => this.searchCancel(closeFn, 'name')}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { id, name } = row
            if (!id || !name) {
              return `something missing`
            }
            const to = { name: 'EditProduct', params: { productId: id } }
            return (
              <router-link to={to} id={id} label={name}>
                {name}
              </router-link>
            )
          },
        },
        {
          field: 'icon',
          key: 'icon',
          title: 'Icon',
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (!row.iconDark) {
              return '-'
            }

            return <ResponsiveImageAtom upload={row.iconDark} expectedSizes={['120px']} />
          },
        },
        {
          field: 'type',
          key: 'type',
          title: 'Type',
          align: 'left',
        },
        {
          field: 'tierLevelCount',
          key: 'tierLevelCount',
          title: 'Tier Levels',
          align: 'left',
        },
        {
          field: 'translations',
          key: 'translations',
          title: 'Translations',
          align: 'left',
        },
        {
          field: 'bundledByCount',
          key: 'bundledByCount',
          title: 'Bundled By',
          align: 'left',
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          width: '10%',
          center: 'left',
          renderBodyCell: ({ row }) => {
            const { id, name } = row
            const to = { name: 'EditProduct', params: { productId: id } }
            return (
              <div class="actions">
                <router-link to={to} id={id} label={name}>
                  <Edit2Icon size="1.3x" />
                </router-link>
                <button on-click={() => this.confirmDeleteProduct(row)} disabled={!this.hasDeletePermission}>
                  <TrashIcon size="1.3x" />
                </button>
              </div>
            )
          },
        },
      ]
      return header
    },
    rows() {
      const tableData = this.products.map(product => {
        return {
          ...product,
          tierLevelCount: product.tierLevels.length,
          translations: `(${product.translatedTexts.length} / 6)`,
          bundledByCount: product.tierLevels.reduce((acc, tierLevel) => {
            tierLevel.bundledBy.forEach(bundle => {
              acc.add(bundle?.product?.id)
            })
            return acc
          }, new Set())?.size,
        }
      })
      return tableData
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      if (pageIndex === this.pageIndex) {
        return
      }
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route?.query,
          page: pageIndex === this.defaultPage ? undefined : pageIndex,
        },
      })
    },
    reload() {
      this.$apollo.queries.products.refetch()
    },
    clearSearch() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route?.query,
          search: undefined,
          page: undefined,
        },
      })
    },
    searchCancel(closeFn) {
      this.clearSearch()
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    confirmDeleteProduct(row) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Product',
        instance: this.products.find(f => f.id === row.id),
        labelKey: 'name',
        onConfirm: this.executeDeleteProduct,
        onConfirmArgs: row.id,
      })
    },
    async executeDeleteProduct(productId) {
      await this.$apollo.mutate({
        mutation: DELETE_ONE_I_PRODUCT_MUTATION,
        variables: {
          where: {
            id: productId,
          },
        },
      })
      this.$apollo.queries.products.refetch()
      return true
    },
  },
  apollo: {
    products: {
      query: LIST_PRODUCTS_QUERY,
      variables() {
        return {
          where: {
            search: this.search,
          },
        }
      },
      manual: true,
      errorPolicy: 'all',
      error() {
        return false
      },
      result({ data, error, errors }) {
        if (error || errors?.length > 0) {
          this.errors = [...(errors ?? [])]
          if (error) {
            this.errors = [error, ...this.errors]
          }
        } else {
          this.errors = []
        }
        if (data?.products) {
          this.products = data.products
        }
      },
    },
  },
}
</script>
